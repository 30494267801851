// Generated by Framer (c563d2c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["M4qgoLzMO", "Ng3uRVo2X", "tY0LV5jB3"];

const serializationHash = "framer-Slxxi"

const variantClassNames = {M4qgoLzMO: "framer-v-fk2g0g", Ng3uRVo2X: "framer-v-1dqyxl8", tY0LV5jB3: "framer-v-pnhfny"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "M4qgoLzMO", Mobile: "tY0LV5jB3", Tablet: "Ng3uRVo2X"}

const getProps = ({desktop, height, id, mobile, tablet, width, ...props}) => { return {...props, deTuAF8Z8: mobile ?? props.deTuAF8Z8, JSNyhAS_h: tablet ?? props.JSNyhAS_h, oECTaQios: desktop ?? props.oECTaQios, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "M4qgoLzMO"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;desktop?: {src: string; srcSet?: string; alt?: string};tablet?: {src: string; srcSet?: string; alt?: string};mobile?: {src: string; srcSet?: string; alt?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, oECTaQios, JSNyhAS_h, deTuAF8Z8, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "M4qgoLzMO", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 751, intrinsicWidth: 767, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 751, pixelWidth: 767, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(oECTaQios)}} className={cx(scopingClassNames, "framer-fk2g0g", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"M4qgoLzMO"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} variants={{tY0LV5jB3: {borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}} {...addPropertyOverrides({Ng3uRVo2X: {"data-framer-name": "Tablet", background: {alt: "", fit: "fill", intrinsicHeight: 751, intrinsicWidth: 767, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 751, pixelWidth: 767, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(JSNyhAS_h)}}, tY0LV5jB3: {"data-framer-name": "Mobile", background: {alt: "", fit: "fill", intrinsicHeight: 751, intrinsicWidth: 767, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 751, pixelWidth: 767, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(deTuAF8Z8)}}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Slxxi.framer-3q0crv, .framer-Slxxi .framer-3q0crv { display: block; }", ".framer-Slxxi.framer-fk2g0g { height: 560px; overflow: visible; position: relative; width: 547px; }", ".framer-Slxxi.framer-v-1dqyxl8.framer-fk2g0g { height: 520px; width: 373px; }", ".framer-Slxxi.framer-v-pnhfny.framer-fk2g0g { height: 224px; width: 435px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 560
 * @framerIntrinsicWidth 547
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Ng3uRVo2X":{"layout":["fixed","fixed"]},"tY0LV5jB3":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"oECTaQios":"desktop","JSNyhAS_h":"tablet","deTuAF8Z8":"mobile"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBXk7J_0QH: React.ComponentType<Props> = withCSS(Component, css, "framer-Slxxi") as typeof Component;
export default FramerBXk7J_0QH;

FramerBXk7J_0QH.displayName = "Hero Image";

FramerBXk7J_0QH.defaultProps = {height: 560, width: 547};

addPropertyControls(FramerBXk7J_0QH, {variant: {options: ["M4qgoLzMO", "Ng3uRVo2X", "tY0LV5jB3"], optionTitles: ["Variant 1", "Tablet", "Mobile"], title: "Variant", type: ControlType.Enum}, oECTaQios: {title: "Desktop", type: ControlType.ResponsiveImage}, JSNyhAS_h: {title: "Tablet", type: ControlType.ResponsiveImage}, deTuAF8Z8: {title: "Mobile", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerBXk7J_0QH, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})